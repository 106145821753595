
import {
  type GmReportingLibraryModelFinancialReportDto as FinancialReport,
  type GmDtoLibraryDtoInvoiceReceiverDto as InvoiceReceiver,
  type GmDtoLibraryDtoProductDto as Product,
  type GmDtoLibraryDtoContractProjectDto as Project,
  ProjectApi,
  ContractApi,
  type GmDtoLibraryDtoProjectOrderDto as ProjectOrder,
  type GmDtoLibraryDtoProjectOrderLineDto as ProjectOrderLine,
  type GmDtoLibraryDtoProjectReadyToCloseDto as ProjectReadyToClose, ReportsApi, type GmModelLibraryModelsProductModel
} from '@/gen/openapi/sblService/index'
import { useAxiosClient } from '~/use/axios/client'
import { extractErrorMessagesFromErrorOrResponse } from '~/helpers/errors'
import type {AxiosInstance, AxiosResponse} from 'axios'
import { sumStringFloatsReducer } from '~/helpers/numberHelpers'
import { isTempContract } from '~/helpers/contractHelper'
import {notify} from "@gm/components";

const state = reactive({
  currentProject: undefined as Project | null | undefined,
  loadingProject: false,
  projectFetchError: null as string | null | undefined,

  projectFinancialReport: null as FinancialReport | null,
  loadingFinancialReport: false,
  financialReportFetchError: null as string | null | undefined,

  projectOrders: [] as ProjectOrder[],
  loadingProjectOrders: false,
  projectOrdersFetchError: null as string | null | undefined,

  currentOrder: null as ProjectOrder | null,

  orderLines: [] as ProjectOrderLine[],
  loadingOrderLines: false,
  orderLinesFetchError: null as string | null | undefined,

  projectProducts: [] as GmModelLibraryModelsProductModel[],
  loadingProjectProducts: false,
  projectProductsFetchError: null as string | null | undefined,

  invoiceReceivers: [] as InvoiceReceiver[],
  loadingInvoiceReceivers: false,
  invoiceReceiversFetchError: null as string | null | undefined,

  saveInProgress: false,
  saveError: null as string | null | undefined,

  createOrderInProgress: false,
  createOrderError: null as string | null | undefined,

  projectsReady2Close: [] as ProjectReadyToClose[],
  isLoadingProjectsReady2Close: false,
  projectsReady2CloseFetchError: null as string | null | undefined,
})

const currentProject = computed(() => state.currentProject)
const loadingProject = computed(() => state.loadingProject)
const projectFetchError = computed(() => state.projectFetchError)
const projectFinancialReport = computed(() => state.projectFinancialReport)
const loadingFinancialReport = computed(() => state.loadingFinancialReport)
const financialReportFetchError = computed(
  () => state.financialReportFetchError
)
const projectOrders = computed(() => state.projectOrders)
const loadingProjectOrders = computed(() => state.loadingProjectOrders)
const projectOrdersFetchError = computed(() => state.projectOrdersFetchError)
const currentOrder = computed(() => state.currentOrder)
const orderLines = computed(() => state.orderLines)
const loadingOrderLines = computed(() => state.loadingOrderLines)
const orderLinesFetchError = computed(() => state.orderLinesFetchError)
const projectProducts = computed(() => state.projectProducts)
const loadingProjectProducts = computed(() => state.loadingProjectProducts)
const projectProductsFetchError = computed(() => state.projectProductsFetchError)
const invoiceReceivers = computed(() => state.invoiceReceivers)
const loadingInvoiceReceivers = computed(() => state.loadingInvoiceReceivers)
const invoiceReceiversFetchError = computed(
  () => state.invoiceReceiversFetchError
)

const saveInProgress = computed(() => state.saveInProgress)
const saveError = computed(() => state.saveError)
const createOrderInProgress = computed(() => state.createOrderInProgress)
const createOrderError = computed(() => state.createOrderError)

const projectsReady2Close = computed(() => state.projectsReady2Close)
const isLoadingProjectsReady2Close = computed(() => state.isLoadingProjectsReady2Close)
const projectsReady2CloseFetchError = computed(() => state.projectsReady2CloseFetchError)

/* helper to try to keep "load{thing}" functions dry */
async function load<T>({
  state,
  propName,
  loadName,
  errName,
  loadFn
}: {
  state: { [key: string]: any }
  propName: string
  loadName: string
  errName: string
  loadFn: () => Promise<AxiosResponse<T>>
}) {
  if (loadName) {
    state[loadName] = true
  }
  if (errName) {
    state[errName] = null
  }
  try {
    const response = await loadFn()
    state[propName] = response.data
  } catch (e) {
    console.error(e)
    if (errName) {
      state[errName] =
        extractErrorMessagesFromErrorOrResponse(e)[0] ||
        'UNKNOWN_ERROR'
    }
  } finally {
    if (loadName) {
      state[loadName] = false
    }
  }
}

const mockBasePath = 'https://stoplight.io/mocks/headit/gm/61702685'
const axiosWrapper = (axios: AxiosInstance) => {
  const projectApi = new ProjectApi(
    undefined,
    '',
    axios
  )
  const contractApi = new ContractApi(
    undefined,
    '',
    axios
  )
  const reportsApi = new ReportsApi(
    undefined,
    '',
    axios
  )

  const loadProject = async (contractId: string) => {
    if (isTempContract(contractId)) {
      return;
    }
    state.projectFinancialReport = null
    state.projectOrders = []
    if (!state.loadingProject) {
      await load({
        state,
        propName: 'currentProject',
        loadName: 'loadingProject',
        errName: 'projectFetchError',
        loadFn: () => contractApi.contractGetContractProject(contractId)
      })
    }
  }

  const fulfilProject = async (contractId: string, projectId: string) => {
    state.saveInProgress = true
    state.saveError = null
    try {
      await projectApi.projectFulfillProject(contractId, projectId)
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }

  const reopenProject = async (contractId: string, projectId: string) => {
    state.saveInProgress = true
    state.saveError = null
    try {
      await projectApi.projectReopenProject(contractId, projectId)
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }

  const loadFinancialReport = async (
    contractId: string,
    vismaId: string,
    erpSystemId: string
  ) => {
    await load({
      state,
      propName: 'projectFinancialReport',
      loadName: 'loadingFinancialReport',
      errName: 'financialReportFetchError',
      loadFn: () =>
        reportsApi.reportsGetProjectFinancialReport(contractId, vismaId, erpSystemId)
    })
  }

  const loadProjectOrders = async (projectId: string, contractId: string) => {
    await load({
      state,
      propName: 'projectOrders',
      loadName: 'loadingProjectOrders',
      errName: 'projectOrdersFetchError',
      loadFn: () => projectApi.projectGetProjectOrders(contractId, projectId)
    })
  }

  const getProjectOrder = async(contractId: string, projectId: string, orderId: string): Promise<ProjectOrder> => {
    try {
      const response = await projectApi.projectGetProjectOrder(contractId, orderId, projectId)
      if (response.status >= 400) {
        console.error('Could not fetch order: ', response)
      }
      return response.data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const setCurrentOrder = (order: ProjectOrder) => (state.currentOrder = order)
  const saveOrder = async (projectId: string, contractId: string, order: ProjectOrder) => {
    // save current order? or arg?
    try {
      let request: Promise<AxiosResponse<ProjectOrder>>
      state.saveInProgress = true
      state.saveError = null
      if (!order.Id) {
        request = projectApi.projectCreateProjectOrder(contractId, projectId, order)
      } else {
        request = projectApi.projectUpdateProjectOrder(
          contractId,
          projectId,
          order.Id,
          order
        )
      }

      const response = await request
      state.currentOrder = response.data
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }
  const deleteOrder = async () => {
    console.log('todo: implement delete order')
  }

  const loadOrderLines = async (contractId: string, projectId: string, orderId: string) => {
    await load({
      state,
      propName: 'orderLines',
      loadName: 'loadingOrderLines',
      errName: 'orderLinesFetchError',
      loadFn: () =>
        projectApi.projectGetProjectOrderLines(
          contractId,
          orderId,
          projectId,
        )
    })
  }

  const fulfilOrder = async (contractId: string, projectId: string, orderId: string) => {
    state.saveError = null
    state.saveInProgress = false
    try {
      await projectApi.projectFulfillProjectOrder(contractId, orderId, projectId, {})
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }

  const cancelOrder = async (contractId: string, projectId: string, orderId: string, cancelMessage: string) => {
    state.saveError = null
    state.saveInProgress = false
    try {
      await projectApi.projectCancelProjectOrder(contractId, orderId, projectId, {
        CancelMessage: cancelMessage
      })
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }

  const createOrder = async (contractId: string, projectId: string, order?: ProjectOrder): Promise<ProjectOrder> => {
    state.createOrderInProgress = true
    state.createOrderError = null
    try {
      const response = await projectApi.projectCreateProjectOrder(contractId, projectId, order)
      return response.data
    } catch (e) {
      console.error(e)
      state.createOrderError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.createOrderInProgress = false
    }
  }

  const saveOrderLine = async (contractId: string, projectId: string, orderId: string, line: ProjectOrderLine): Promise<void> => {
    try {
      state.saveInProgress = true
      state.saveError = null
      if (line.Id) {
        await projectApi.projectUpdateProjectOrderLine(contractId, orderId, projectId, line.Id, line)
      } else {
        await projectApi.projectCreateProjectOrderLine(contractId, orderId, projectId, line)
      }
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }

  const deleteOrderLine = async (
    contractId: string,
    projectId: string,
    orderId: string,
    orderLineId: string
  ) => {
    state.saveInProgress = true
    state.saveError = null
    try {
      await projectApi.projectDeleteProjectOrderLine(contractId, projectId, orderId, orderLineId)
    } catch (e) {
      console.error(e)
      state.saveError = extractErrorMessagesFromErrorOrResponse(e)[0]
      throw e
    } finally {
      state.saveInProgress = false
    }
  }

  const loadInvoiceReceivers = async () => {
    await load({
      state,
      propName: 'invoiceReceivers',
      loadName: 'loadingInvoiceReceivers',
      errName: 'invoiceReceiversFetchError',
      loadFn: () => projectApi.projectGetInvoiceReceivers()
    })
  }

  const loadProducts = async () => {
    await load({
      state,
      propName: 'projectProducts',
      loadName: 'loadingProjectProducts',
      errName: 'projectProductsFetchError',
      loadFn: () => projectApi.projectGetProducts()
    })
  }

  const getInvoicePreview = async (contractId: string, orderId: string) => {
    try {
      const response = await reportsApi.reportsGetProFormalInvoiceReport(contractId, orderId, {
        responseType: 'blob'
      })
      return response.data
    } catch (error: any) {
      console.error(error)
      notify(error.toString(), {
        type: 'error',
        icon: 'exclamation',
        closeable: true
      })
    }
  }

  const resetSaveError = () => {
    state.saveError = null
  }

  /**
   * The difference between how much volume the entrepreneur has agreed upon,
   * and the actual volume they have sent an invoice for
   */
  const entrepreneurDeviationQuantity = computed(() => {
    const entrepreneurAccounts = (projectFinancialReport.value?.EntrepreneurAccounts || [])
    return entrepreneurAccounts.reduce(
      sumStringFloatsReducer('DeviationQuantity'),
      0
    )
  })

  const triggerFinalEmailToEntrepreneur = async (contractId: string, entrepreneurId: string) => {
    try {
      await projectApi.projectProjectEmailDunningFinalInvoice(contractId, entrepreneurId)
    } catch (e) {
      console.error("Failed to trigger final invoice email", e)
      throw e
    }
  }

  const loadProjectsReady2Close = async () => {
    await load({
      state,
      propName: 'projectsReady2Close',
      loadName: 'isLoadingProjectsReady2Close',
      errName: 'projectsReady2CloseFetchError',
      loadFn: () => projectApi.projectProjectsReadyToClose()
    })
  }

  return {
    currentProject,
    loadingProject,
    projectFetchError,
    fulfilProject,
    reopenProject,

    projectFinancialReport,
    loadingFinancialReport,
    financialReportFetchError,

    projectOrders,
    loadingProjectOrders,
    projectOrdersFetchError,

    getProjectOrder,

    currentOrder,

    orderLines,
    loadingOrderLines,
    orderLinesFetchError,

    loadProducts,
    projectProducts,
    loadingProjectProducts,
    projectProductsFetchError,

    invoiceReceivers,
    loadingInvoiceReceivers,
    invoiceReceiversFetchError,
    loadProject,
    loadFinancialReport,
    loadProjectOrders,
    setCurrentOrder,
    saveOrder,
    deleteOrder,
    fulfilOrder,
    cancelOrder,
    createOrder,

    loadOrderLines,
    saveOrderLine,
    deleteOrderLine,

    loadInvoiceReceivers,

    saveInProgress,
    saveError,
    createOrderInProgress,
    createOrderError,

    getInvoicePreview,

    resetSaveError,

    entrepreneurDeviationQuantity,
    triggerFinalEmailToEntrepreneur,

    projectsReady2Close,
    loadProjectsReady2Close,
    isLoadingProjectsReady2Close,
    projectsReady2CloseFetchError,
  }
}

export const useProjectStore = () => {
  const { axiosClient } = useAxiosClient()
  return axiosWrapper(axiosClient.value)
}
