/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmReportingLibraryModelFinancialReportDto } from '../model';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get proformal report for project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetProFormalInvoiceReport: async (contractId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('reportsGetProFormalInvoiceReport', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('reportsGetProFormalInvoiceReport', 'orderId', orderId)
            const localVarPath = `/v1/reports/{contractId}/proFormalInvoice/{orderId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project financial report from Reporting services
         * @param {string} contractId 
         * @param {string} vismaId 
         * @param {string} erpSystemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetProjectFinancialReport: async (contractId: string, vismaId: string, erpSystemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('reportsGetProjectFinancialReport', 'contractId', contractId)
            // verify required parameter 'vismaId' is not null or undefined
            assertParamExists('reportsGetProjectFinancialReport', 'vismaId', vismaId)
            // verify required parameter 'erpSystemId' is not null or undefined
            assertParamExists('reportsGetProjectFinancialReport', 'erpSystemId', erpSystemId)
            const localVarPath = `/v1/reports/{contractId}/projectFinancial/{vismaId}/{erpSystemId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"vismaId"}}`, encodeURIComponent(String(vismaId)))
                .replace(`{${"erpSystemId"}}`, encodeURIComponent(String(erpSystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get proformal report for project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetProFormalInvoiceReport(contractId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetProFormalInvoiceReport(contractId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.reportsGetProFormalInvoiceReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get project financial report from Reporting services
         * @param {string} contractId 
         * @param {string} vismaId 
         * @param {string} erpSystemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetProjectFinancialReport(contractId: string, vismaId: string, erpSystemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmReportingLibraryModelFinancialReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetProjectFinancialReport(contractId, vismaId, erpSystemId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.reportsGetProjectFinancialReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get proformal report for project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetProFormalInvoiceReport(contractId: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.reportsGetProFormalInvoiceReport(contractId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project financial report from Reporting services
         * @param {string} contractId 
         * @param {string} vismaId 
         * @param {string} erpSystemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetProjectFinancialReport(contractId: string, vismaId: string, erpSystemId: string, options?: any): AxiosPromise<GmReportingLibraryModelFinancialReportDto> {
            return localVarFp.reportsGetProjectFinancialReport(contractId, vismaId, erpSystemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get proformal report for project order
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetProFormalInvoiceReport(contractId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetProFormalInvoiceReport(contractId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project financial report from Reporting services
     * @param {string} contractId 
     * @param {string} vismaId 
     * @param {string} erpSystemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetProjectFinancialReport(contractId: string, vismaId: string, erpSystemId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsGetProjectFinancialReport(contractId, vismaId, erpSystemId, options).then((request) => request(this.axios, this.basePath));
    }
}

